<template>
  <div class="d-flex flex-column card-wrapper news-wrapper">
    <div class="v-card">
      <div class="cart-title">Новости обновлений</div>
      <p class="date-publication">1.03.2024</p>

      <div class="wrapper-update-list">
        <p>
          – Доработали парсер<br/>
          — Добавили виджет для скачивания мобильного приложения<br/>
          — Доработали фильтр объектов<br/>
          — Доработали работу со слоями объектов в мобильном приложении <br/>
        </p>
      </div>
      <div class="subtitle-1">История обновлений</div>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            22.02.2024
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            Поработали над неисправностями<br/>
            — Оптимизировали работу парсера на&nbsp;больших данных<br/>
            — Добавили интерфейс отключения слоя для редактирования<br/>
            — Сделали вертексы в&nbsp;полигонах и&nbsp;линиях более заметными при редактировании<br/>
            — Вывели масштабную линейку<br/>
            — Скачивание тайлов (в&nbsp;том числе geotiff)&nbsp;&mdash; оффлайн режим<br/>
            — Сделали более компактными поля для ввода атрибутов<br/>
            — Доработали меньший зум на карте<br/>
            — Очищать введенные значения в&nbsp;полях<br/>
            <b>Мобильное приложение</b><br/>
            — Переработали режим создания объектов<br/>
            — Оптимизировали навигацию по&nbsp;паспортам&nbsp;&mdash; при выборе паспорта скрывать панель
            навигации.<br/>
            — Добавили интерфейс отключения слоя для редактирования.
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
export default {
  name: 'News'
}
</script>

<style scoped>
  .news-wrapper {
    height: 100vh;
    overflow-x: hidden;
  }
</style>