<template>
  <div class="d-flex flex-column card-wrapper color-white blue-gr-bg">
    <div class="tizer">
      <div class="cart-title mb-8">Мы полностью обучаем
        вас работе в системе:
      </div>

      <p>Менеджер объяснит всю логику
        работы и все плюсы, которые
        вы можете получить
      </p>

      <p>На протяжении всего периода
        использования Единой среды
        мы оказываем вам сервисную
        и техническую поддержку
      </p>

      <p>Бесплатно обучаем ваших
        новых специалистов
        работе в системе
      </p>
    </div>

    <div class="card mb-10 mt-4">
      <div class="cart-title mb-8">Записаться на онлайн<br>обучение</div>
      <h1>8(800) 550-56-12<br>
        <a href="mailto:edinayasreda@ya.ru"
           style="color: #fff">edinayasreda@ya.ru</a>
      </h1>

      <p>
        <v-btn
          small
          outlined
          color="white"
          rounded
          target="_blank"
          :href="guidePath"
        >
          <v-icon class="mr-2">mdi-television-guide</v-icon>
          Руководство пользователя ЕС
        </v-btn>
      </p>

      <p>
        <v-btn
          small
          outlined
          color="white"
          rounded
          target="_blank"
          href="https://t.me/+-hPTM2TMd_5iMjky"
        >
          Чат пользователей
        </v-btn>
      </p>
    </div>
    <div class="img-sasha"><img src="~@/assets/sasha.png"></div>
  </div>
</template>

<script>
import { BASE_URL } from '@/constants/urls';
import MobAppDownload from '../../../base/MobAppDownload'

export default {
  name: 'Contacts',
  components: { MobAppDownload },
  data () {
    return {
      guidePath: BASE_URL + 'guides.pdf'
    }
  }
}
</script>

<style scoped>

</style>