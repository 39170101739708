<template>
  <div class="wrapper-help">
    <MobAppDownload/>
    <Contacts/>
  </div>
</template>

<script>
import News from '../../components/views/account/help/News'
import Contacts from '../../components/views/account/help/Contacts'
import MobAppDownload from '../../components/base/MobAppDownload'
import { BASE_URL } from '@/constants/urls';

export default {
  name: 'Help',
  components: { MobAppDownload, Contacts, News },
  data () {
    return {
      guidePath: BASE_URL + 'guides.pdf'
    }
  }
}
</script>

<style>

  .wrapper-help {
    display: flex;
  }
  .cart-title {
    font-size: 2rem;
  }
  p {
    font-size: 16px;
  }
  .color-white * {
    color: #fff;
  }
  .card-wrapper {
    padding: 60px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
  }
  .blue-gr-bg {
    background: rgb(2, 103, 143);
    background: linear-gradient(126deg, rgba(2, 103, 143, 1) 30%, rgba(26, 191, 255, 1) 95%);
  }
  .img-sasha {
    position: absolute;
    right: -10px;
    bottom: -7px;
    width: 300px;
    z-index: 0;
  }
  .img-sasha img {
    width: 100%;
  }
  .tizer {
    position: relative;
    width: 100%;
  }
  .wrapper-update-list p {
    line-height: 2;
    font-size: 14px;
  }
  .date-publication {
    color: #666;
    font-size: 12px;
  }
</style>
